import * as React from "react"
import styled from "styled-components"

const PlainButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border: none !important;
    outline: none;
  }
`

export default PlainButton
